#why {
    display: flex;
    flex-direction: row-reverse;
   
    background-blend-mode: multiply, multiply;
    height: fit-content;
    
}

.why1 {
    background-color: rgba(0, 0, 0, 0.473);
    display: flex;
    width: 40%;
    color: white;
    padding: 120px 50px 150px 50px;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
    margin: 100px;
    /* border-radius:50px; */
    align-items: center;
}


.why2 {
    display: flex;
    flex-direction: column;
    width: 60%;
    overflow: hidden;
}
#BGV2{
    display: flex;
    width: 100%;
    position: absolute;
    align-items: center;
    overflow: hidden;
    z-index: -1;
}
@media screen and (max-width: 800px){

    .why2{
        display: none;
    }
    .why1{
        width: 100%;
        
        background-size:cover;
        margin: 0;
        border-radius:0px;
        
    }
    #BGV2{
        position: absolute;
       
    object-fit: cover;
    height: 100%;
        }
}


@media screen and (max-width: 1500px){

    .why1 {
      
        
        padding: 15px 50px 30px 50px;
        margin: 20px 10px 20px 10px;
    }
}
@media screen and (max-width: 1274px){

    .why1 {
      
        
        width: 100%;
    }
   

}