/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --appColor:  #3c3f45;
}
body{
  background-color: #000000;
}

.border{
  height: 10px;
  width: 100%;
  background-color:black;
}

.App{
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 5px;
  
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
	/* border: 2px solid #555555; */
}

html{
  scroll-behavior: smooth;
}
.title {

  font-size: 50px;
  color:transparent;
  font-weight:600;
  color: aliceblue;
  background-blend-mode: color;
  text-align: center;
  padding-bottom:30px;
    /* background-size: contain; */
  /* /* width: 100%; */
  background-image: linear-gradient(to left, white,gray,black,rgb(84, 1, 84),purple,white);
  /* background: linear-gradient(to right, #9A08CF, #C8CF08 ); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 50s infinite;
  background-size: 900% 900%;
  background-repeat: repeat-x;
  
  
}
.title2 {
  color: white;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  
  
}
.description{
  font-size: 17px;
  color: white;
  text-align: center;

}
.btn{
  color: rgb(255, 255, 255);
  
  width: max-content;
  text-align: center;
  border-radius: 50px;
  border: 0px;
  background-size: 300% 200%;
  
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
}

.btn:hover {
  
  transition: 0.4s;
  background-position: 100% 0;
  text-decoration: none;
}

@keyframes rainbow{
  0%{
      background-position: 0% ;
  }
  
  100%{
    background-position: 1000% ;
  }
}
@media screen and (max-width: 768px){
.title {
  font-size: 30px;
  font-weight: bold;

}
.description{
  font-size: 15px;
  color: white;

}
.title2 {
  color: white;
  font-size: 20px;
  font-weight: 300;
  
}
}

