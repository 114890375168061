#abt{
    display: flex;
    z-index: 2;
    margin-top: 80px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.473);
    text-align: center;
    align-content: center;
    justify-content: center;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #0dff00);
	background-size: 400% 400%;
	animation: gradient 500s linear infinite;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    background-image: url('abtbg.jpg');
    background-size: cover;
    background-blend-mode: color-dodge;
    
}
.abt2{
    display: flex;
    margin: 80px;    
    width: 50%;
    gap: 20px;
    padding: 50px;
    flex-direction: column;
    background-color:transparent;
    background-color: rgba(0, 0, 0, 0.4);
    
    
}
@keyframes gradient {
	0% {
		background-position: 0% ;
	}
	
    100% {
		background-position:  1000%;
	}
    
    
    

}

@media screen and (max-width: 768px){

    #abt{
        margin-top: 0;
    }
    .abt2{
        margin: 40px 40px 40px 40px ;
        width: 100%;
    }
}
