#Inter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;


}

.Inter1 {
    color: white;
    width: 100%;
    height: 100%;
    /* display: flex; */
    flex-direction: row-reverse;
    /* background-image: radial-gradient(circle farthest-corner at 92.3% 71.5%, rgba(83, 138, 214, 1) 0%, rgba(134, 231, 214, 1) 90%); */
    background-image: linear-gradient(135deg, #70F570 10%, #49C628 100%);
    align-items: center;
    justify-content: flex-end;
}

.Inter2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-image: url('inter.jpg');
    background-position: center;
    background-size: cover;
    justify-content: flex-end;
    margin-top: 10px;
}

.Inter3 {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: row;
}

.D1 {
    position:absolute;
    background-color: rgb(0 120 16 / 30%);
    display: flex;
    width: 30%;
    padding: 120px 50px 150px 50px;
    margin: 50px;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 12;
}
.zom {
    z-index: 10;
    overflow: hidden;
    
}
.zom img{
	
	
	left: 50%;
	
	width: 100%;

  scale: 1.2;
  
}

.D2 {

    background-size: cover;
    /* background-image: url('green.jpg'); */
    display: flex;
    width: 50%;
    height: 500px;
    gap: 20px;
    flex-direction: column;
    overflow: hidden;

    padding: 50px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.3);
    

}

.D3 {
    margin: 100px;
    padding: 150px 50px 180px 50px;
    display: flex;
    width: 30%;
    gap: 20px;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    background-color: #552b0099;
    
}

.D4 {
    display: flex;
    display: none;
    width: 50%;
    height: 500px;
    gap: 20px;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    align-items: center;
    background-image: url('inter.jpg');
    background-size: cover;

}
@media screen and (max-width: 1400px){


    .D1{
        padding: 20px 20px 20px 20px;
        margin: 10px;
        width: 50%;
        align-items: center;
       
        
        
    }
    .Inter1{
        background-image: url(green2.jpeg);
        background-position: center;
    }
    .zom img{
	width: auto;  
    }
    .Inter1{
        display: flex;
    }
}



@media screen and (max-width: 900px) {

    .Inter1 {
        width: 100%;
        flex-direction: column;
    }
    .zom{display: none;}

    .D1 {
        width: fit-content;
        height: fit-content;
        font-size: smaller;
        
        position: relative;
        margin: 0;
        padding: 40px;
        background-size: cover;
        background-blend-mode: color-dodge;
        background-color: rgba(0, 0, 0, 0.4);
        background-position: center;

    }

    .D2 {
        display: none;
    }

    .D4 {
        display: none;
    }

    .D3 {
        
        margin: 0px;
        font-size: smaller;
        width: fit-content;
        height: fit-content;
        background-image: url('inter.jpg');
        background-size: cover;
        background-blend-mode: color-dodge;
        background-color: rgba(0, 0, 0, 0.3);

    }

}
