#footer {
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Open Sans', sans-serif;
    background-color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 30);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    bottom: 0;
    width: 100%;
    gap: 20px;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;   
}
.footer1 {
    background-color: rgb(0, 0, 30);
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    
}
.Contact{
    /* font-family: 'Roboto', sans-serif; */
    font-family: Arial, Helvetica, sans-serif;
    /* color: rgb(0, 0, 90); */
    font-family: 'Open Sans', sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;
     /* font-size:15px; */
    /* font-weight: 800; */
}
.Contact>h1{
    
    padding: 0px 0px 0px 40px;
}
.Contact3{
    /* font-family: 'Roboto', sans-serif; */
    font-family: Arial, Helvetica, sans-serif;
    /* color: rgb(0, 0, 90); */
    font-family: 'Open Sans', sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;
    align-items: center;
    /* font-size:15px; */
    /* font-weight: 800; */
}
.Contact1{
    
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 25%;
}
.Contact2{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

}
.social{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    
}
.social-icons{
    border-radius: 10px;
    width: 25px;
    height: 25px;
}
.social-icons:hover{
    opacity: 0.8;
    transform: scale(1.2);
    cursor: pointer;
    transition: 500ms;
}

.qp_iframe{
    font-size: 5px;
    margin: 0px;
    background-color: black;
    padding: 0;
}
.Makeinindia{
 display: flex;
 width: 115px;
 align-items: center;
}



@media screen and (max-width: 1170px){


.footer1{
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-bottom: 10%;
    align-items: center;
}
.footer1>:nth-child(2){
    width: 90%;
    height: 50%;
    font-size: smaller;
}
.footer1>:nth-child(1){
    width: 90%;
    font-size: smaller;
}
iframe{
    display: flex;
    width:fit-content;
    object-fit:cover;
    flex-direction: column;
    align-items: center;
}
.Contact{
    width: 100%;
}
.Contact>h1{
    display: flex;
    justify-content: center;
    padding: 0px 0px 0px 0px;
}
.Contact>h1{
   margin-top: 60px;
   
}
.Contact3{
    width: 90%;
}
.Contact1>h1{
    margin-top: 60px;
}
.Contact2>h1{
    margin-top: 60px;
}
.Contact3>h1{
    margin-top: 60px;
}
}
