#Vision {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  z-index: 1;
  justify-content: flex-start;
    
}

.Vision1 {
  position:absolute;
    background-color: rgba(0, 0, 0, 0.473);
    /* background-image: linear-gradient(to left, #434343 0%, black 100%); */
    display: flex;
    width: 30%;
    padding: 120px 50px 150px 50px;
    margin: 100px;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 12;
    
    
}

.zoom {
    z-index: 10;
    overflow: hidden;
    
}
.zoom img{
	
	
	left: 50%;
	
	width: 100%;

  scale: 1.3;
  
}

p{
    color: white;
    font-size: 15px;
}
#reveal{
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.8s all ease;
  }
  #reveal.active{
    transform: translateY(0);
    opacity: 1;
  }
  .active.fade-bottom {
    animation: fade-bottom 1s ease-in;
  }
  .active.fade-left {
    animation: fade-left 1s ease-in;
  }
  .active.fade-right {
    animation: fade-right 1s ease-in;
  }

  @keyframes fade-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-left {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

@media screen and (max-width: 800px){
  
  .zoom{
    display: none;
  }
  .Vision1{
    display: flex;
    width: 100%;
    background-image: url('V1.jpg');
    background-blend-mode: color-dodge;
    background-size: cover;
    position: inherit;
    padding: 80px;
    margin: 0px;
  }

  .title2{
    font-size: large;
  }
  p{
    color: white;
    font-size: smaller;
}

}

@media screen and (min-width: 1700px){


  #Vision {
    display: block;
      
  }
}

@media screen and (max-width: 1000px){

  .Vision1 {
    
      
      padding: 60px 50px 90px 50px;
  }
}
