.C {
    font-size: 200%;
    display: flex;
    width: 100%;
    height: 700px;
    justify-content: center;
    color: white;
    flex-direction: column;
    align-content: center;
    align-items: center;
    
    /* background-image: url('idea.jpg'); */
}

#BGV3 {
    display: flex;
    width: 100%;
    z-index: -1;
    
    


}
.vido{
    width:90%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.lr {
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('jobbg.jpg');
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;


}

.right {
    color: white;
    display: flex;
    width: 30%;
    margin: 100px;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    padding: 150px 50px 150px 50px;

}

#job {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: rgba(0, 0, 0, 0.327);
    color: white;
    width: 20%;
    height: 400px;
    overflow-x: scroll;

    transition: 0.5s;
}

#list {
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    gap: 50px;
    text-align: start;
    height: 600px;

}

.JobD {
    overflow-y: scroll;


}



#job:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.jobtitle {
    font-size: 25px;
    stroke: 2px black;
}

/* .textstyle {
    color: black;
    z-index: 10;
    font-family: 'Michroma', sans-serif;
    text-align: center;
    font-size: 90px;
    letter-spacing: 8px;
    margin-bottom: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    animation: maintransitionfocusin 2s;
    text-shadow: 2px 2px 0 #bcbcbc, 4px 4px 0 #9c9c9c;
} */

.textstyle2 {
    
    color:#ccc;
    z-index: 10;
    font-size: 40px;
    animation: maintransitionup 2s;
    margin-top: 20px;
}

.CBG {
    padding-bottom: 30px;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.HH {

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}


/* flip cards */
.flip-card {
    font-family: 'Open Sans', sans-serif;
    width: 25%;
    height: 450px;
    perspective: 1000px;
    transition: 1s;
    background-color: rgba(0, 0, 0, 0.327);
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.6s;
    transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
    transition: 1s;

}

.flip-card:hover {
    transform: scale(1.05);


}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {

    color: white;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flip-card-back {

    display: flex;
    color: white;
    transform: rotateY(180deg);
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

@keyframes maintransitionfocusin{
    0%{
        filter: blur(12px);
        opacity: 0;
        
    }
    
    100%{
        filter: blur(0px);
    opacity: 1;
        
    }
}
@keyframes maintransitionup{
    0%{
        letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
    }
    40% {
        opacity: 0.6;
      }
    
    100%{
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
opacity: 1;
    }
    
    
    }

@media screen and (max-width: 768px) {
    

    #BGV3 {
        
    object-fit: cover;
    height: 100%;
        
    }

    #list {
        flex-direction: column;
        height: fit-content;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .textstyle {
        font-size: 30px;
        font-weight: 800;
        text-shadow: 1px 1px 0 #bcbcbc, 2px 2px 0 #9c9c9c;
    }

    #job {
        margin: 0;
        width: 80%;
        gap: 10px;
        overflow-x: hidden;
        height: fit-content;
    }

    .left {
        display: none;
    }

    .right {
        background-image: url('jobbg.jpg');
        background-position: center;
        background-size: cover;
        background-blend-mode: color-dodge;
        padding: 40px;
        margin: 0px;
        width: 100%;
    }

    .textstyle2 {

        font-size: 20px;
    }

    .flip-card {
        width: 70%;
    }

}