#Nav{
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    z-index: 10;
    width: 100%;  
    height: 70px; 
    transition: 0.5s;
    color: rgb(0, 0, 90);
    z-index: 100;
   
    backdrop-filter: blur(4px);
    
}
#logo{
   
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(0, 0, 90);
    font-weight: bold;
    transition: 1s;
}
.logo{
   
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(0, 0, 90);
    font-weight: bold;
    transition: 2s;
    

}
#LG{
    transition: 500ms;
    margin-left: 50px;
    margin-right: 20px;
    width: 60px;
    display: flex;
}

#NavMenu{
    list-style: none;
    display: flex;
    gap: 35px;
    transition: 1s;
    align-items: center;
    margin-right: 50px;
    text-decoration: none;
    font-weight: bold;
    
    
    
}

.NavMenu>li:hover{
    transform: scale(1.5);
    cursor: pointer;
    
    
    transition: 0.3s ease;
    
}
a{
    text-decoration: none;
    color: rgb(0, 0, 90);
}

#Gharsee{
    transition: 500ms;
    font-size: 30px;
    color: rgb(0, 0, 90);
    
}
#Gharsee1{
    font-size: 12px;
    transition: 1s;
}
#Gharsee2{
    font-size: 12px;
    transition: 1s;
    margin-left: 20px;
    opacity: 0;
}
a:hover{
    color: rgb(147, 147, 147);
    
}
ul>:nth-child(1){
    display: none;
}

@media screen and (max-width: 768px){

 
    #NavMenu{
        
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    gap: 2.5rem;
    margin-right: 0;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    width: 266px;
    height: 100vh;
    color: rgb(0, 0, 90);

    transition: 2s;

}
    #logo{
   
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
       margin-top: 5px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        
        
    
    }
    a{
        text-decoration: none;
        
    }
    #LG{
        margin-left: 20px;
        width: 50px;
        margin-right: 10px;
    }
    #Gharsee{
        
        font-size: 25px;
    }
    #Gharsee1{
        font-size: 9px;
    }
    #Gharsee2{
        display: none;
    }
    .bars{
        height: 2rem;
        height: 2rem;
        display: flex;
        margin-right: 20px;
    }
    ul>:nth-child(1){
        display: flex;
        align-items: flex-end;
    }
}

@media screen and (max-width: 1500px){
    #NavMenu{

        font-size: 12px;
        gap: 20px;
    }
}