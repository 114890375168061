.Home {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    height: 700px;
}


.vid {
    display: flex;
    align-items: center;
    flex-direction: column;
    width:90%;
    
    
}

#BGV1 {
    display: flex;
    width: 100%;
    position: absolute;
    align-items: center;
    z-index: -1;
    object-fit: cover;
    
    

}

#T1 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    vertical-align: baseline;
   
    align-items: center;
    overflow: hidden;
}
.maintitle{
    
    color:rgba(255, 255, 255, 0.885);
    font-weight: bold;
    font-size: 65px;
    text-align: center;
    text-shadow: 2px 2px 0 #000000, 4px 4px 0 #000000;
    animation: maintransitionin 2s;
    font-family: 'Michroma', sans-serif;
    /* font-family: 'Roboto Slab', serif; */
    /* font-family: 'Fredericka the Great', fantasy; */
    letter-spacing: 10px;
    /* text-shadow: #5e97ff -1px 4px 0px, rgb(254 1 1 / 30%) -1px -4px 0px; */
    

}
.maintitle2{
    margin-top: 20px;
    font-size: 30px;
    animation: maintransitionfadein 1.5s;

    
    /* text-shadow: 2px 2px 1px #000000; */
    
    /* background-image: linear-gradient( to right, #97ABFF 10%, #123597 100%,#123597 100%,#97ABFF 10%); */
    
    background-image: linear-gradient( to right, #3B2667 10%, #BC78EC 100%);
    
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 50s infinite;
  background-size: 900% 900%;
  background-repeat: repeat-x;

}

@keyframes maintransitionin{
0%{
    opacity: 0;
    transform: scale(0.5);
    
}

100%{
    opacity: 1;
    transform: scale(1);
    
}


}

@keyframes maintransitionfadein{
    0%{
        opacity: 0;
        transform:translateY(100px);
    }
    
    100%{
        opacity: 1;
        transform:translateY(0px) ;
    }
    
    
    }

@media screen and (max-width: 1000px){

    #BGV1 {
        
        object-fit: cover;
        height: inherit;
        
        
        
    
    }
    .maintitle{
        
        
        font-size:18px;
        animation: maintransitionin 2s;
        text-shadow: 1px 1px 0 #000000, 2px 2px 0 #000000;
    
    }
    .maintitle2{
        font-size: 15px;
        align-items: center;
        text-align: center;
        font-weight:900;
        /* text-shadow: 1px 1px   #000000; */
    }

}