.CH{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;  
    background-image: url('Career.jpg');
}

.CH1{
    width: 65%;
    display: flex;
    height: 500px;
    gap: 30px;
    padding: 50px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    
    background-color: rgb(0, 0, 0,0.3);
    
}
.CH2{
    display: flex;
    width: 35%;
    height: 100%;
    /* background-color: rgb(0, 0, 0); */
    align-content: center;
    justify-content: center;
    align-items: center

}

.Careers {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    font-weight: bold;

}

.Careers2 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    
}
.iframe{
    width: 90%;
    height: 560px;
    margin: 20px;
}

@media screen and (max-width: 768px){

    .CH2{

        width: 100%;
    }
    .CH{
        width: 100%;
        flex-direction: column;
    }
    .CH1{
        width: 100%;
        height: auto;
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .iframe{
        width: 90%;
        height: 570px;
    }
}
