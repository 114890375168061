#form{
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: scroll;
    height: 90%;
}
input{
    font-size: 15px;
}
textarea{
    height: 80px;
    width: 100%;
}
#form::-webkit-scrollbar
{
	display: none;
}
@media screen and (max-width: 768px){
    input{
        font-size: 15px;
    }
    textarea{
        height: 100px;
        width: 100%;
    }
}